import React from 'react'
import { Typography, makeStyles, createStyles } from '@material-ui/core'

type Tprops = {
  icon?: string
  title?: string
  theme?: any
  desc?: any
  center?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      minHeight: 70,
      padding: theme.spacing(4, 2),
      borderRadius: 6,
      background: theme.palette.primaryTransparent.light,
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        textAlign: 'left',
        minHeight: 100,
        height: '100%',
        padding: theme.spacing(4, 4),
        borderRadius: theme.shape.borderRadius,
      },
      '.MuiGrid-grid-md-4 &': {
        height: 'auto',
        padding: theme.spacing(1, 2),
        margin: theme.spacing(2, 0, 3),
      },
    },
    media: {
      margin: theme.spacing(0, 0, 2),
      '&>img': {
        display: 'block',
        maxWidth: 60,
      },
      [theme.breakpoints.up('lg')]: {
        margin: 0,
        paddingRight: theme.spacing(5),
        '&>img': {
          maxWidth: 60,
        },
      },
    },
    text: {
      flex: 1,
    },
    richText: {
      '& p, & ul, & ol': {
        margin: 0,
      },
      '& a': {
        color: 'currentColor',
        textDecoration: 'underline',
        '&:hover, &:focus': {
          textDecoration: 'none',
        },
      },
    },
    rootCenter: {
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    mediaCenter: {
      margin: theme.spacing(0, 0, 2),
      paddingRight: 0,
    },
    commitmentImage: {
      width: '60px',
    },
  })
)

const Commitment = (props: Tprops) => {
  const classes = useStyles(props)
  const { icon, title, desc, center } = props

  return (
    <div
      className={
        center
          ? `commitment ${classes.rootCenter} ${classes.root}`
          : `commitment ${classes.root}`
      }
    >
      {icon && (
        <div
          className={
            center
              ? `commitment__media ${classes.mediaCenter} ${classes.media}`
              : `commitment__media ${classes.media}`
          }
        >
          <img src={icon} alt="" className={classes.commitmentImage} />
        </div>
      )}
      <div className={`commitment__text ${classes.text}`}>
        {title && (
          <Typography
            variant="h3"
            component="p"
            className={'commitment__title'}
          >
            {title}
          </Typography>
        )}
        {desc && (
          <Typography
            variant="body1"
            component="div"
            className={`commitment__desc ${classes.richText}`}
          >
            {desc}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default Commitment
