import React from 'react'
import clsx from 'clsx'
import {
  Typography,
  Container,
  makeStyles,
  createStyles,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Commitment from '../Commitment/Commitment'
import CMSText, { prismicText } from '../Global/CMSText'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(6, 0),
    },
    box: {
      textAlign: 'center',
      padding: theme.spacing(4, 0),
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        textAlign: 'left',
      },
    },
    box__img: {
      display: 'block',
      objectFit: 'contain',
      width: '80%',
      margin: theme.spacing(0, 'auto', 3),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 3, 0, 0),
      },
    },
    box__total: {
      color: theme.palette.secondary.main,
      margin: theme.spacing(3, 0, 0),
      [theme.breakpoints.up('md')]: {
        margin: 0,
      },
    },
    sectionTitle: {
      marginBottom: theme.spacing(3),
    },
    row: {
      display: 'flex',
    },
    imgContainer: {
      width: '40%',
      marginRight: theme.spacing(2),
    },
    text: {
      alignContent: 'center',
      display: 'grid',
    },
  })
)

type TProps = {
  homePage: any
  t?: any
}

const CommunitySection = ({ homePage, t }: TProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const CMSDATA = homePage?.data
  return (
    <div className={`section-community section__spacer ${classes.root}`}>
      <Container maxWidth="lg">
        <Grid container spacing={3} alignItems="center">
          <Grid xs={12} md={6} item>
            <Commitment
              title={prismicText(
                CMSDATA?.title_commitment,
                t('texts:homepage:title_commitment')
              )}
              icon={
                CMSDATA?.icon_commitment ? CMSDATA.icon_commitment.url : null
              }
              desc={
                <CMSText
                  data={CMSDATA?.description_commitment}
                  defaultText={t('texts:homepage:description_commitment')}
                />
              }
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <div className={classes.box}>
              {useMediaQuery(theme.breakpoints.down('sm')) && (
                <Typography
                  align="center"
                  className={classes.sectionTitle}
                  variant="body1"
                  component="h2"
                >
                  {prismicText(
                    CMSDATA?.section_title_community,
                    t('texts:homepage:section_title_community')
                  )}
                </Typography>
              )}
              <div className={classes.row}>
                <div className={classes.imgContainer}>
                  <img
                    className={classes.box__img}
                    src={
                      CMSDATA?.icon_community
                        ? CMSDATA.icon_community.url
                        : '/images/placeholder.jpg'
                    }
                    alt=""
                  />
                </div>
                <div className={clsx(classes.text, 'section-community__text')}>
                  <Typography
                    variant="h3"
                    component="p"
                    className={clsx('section-community__title')}
                  >
                    {prismicText(
                      CMSDATA?.title_community,
                      t('texts:homepage:title_community')
                    )}
                  </Typography>
                  <Typography
                    variant="h1"
                    component="p"
                    className={clsx(
                      classes.box__total,
                      'section-community__subtitle'
                    )}
                  >
                    {prismicText(
                      CMSDATA?.amount_community,
                      t('texts:homepage:amount_community')
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default CommunitySection
